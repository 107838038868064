import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import {
  getSetting,
  updateSetting,
  updateSettingQuotaTransfer,
  updateSettingRecommended,
} from "../../api/setting";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoading } from "../../redux/app.reducer";
import useSnackbar from "../../utils/snackbar";

const SettingQuotaTransferPage = () => {
  const { data, isLoading, refetch } = useQuery("fetchSettings", async () => {
    return (await getSetting()).data;
  });
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const { register, handleSubmit, setValue } = useForm<{
    allowQuotaTransfer: boolean;
  }>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<{ allowQuotaTransfer: boolean }> = async ({
    allowQuotaTransfer,
  }) => {
    if (!data) return;
    setLoading(true);
    try {
      await updateSettingQuotaTransfer({
        allowQuotaTransfer,
      });
      setLoading(false);
      refetch();
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    if (data) {
      setValue("allowQuotaTransfer", data.allowQuotaTransfer);
    }
  }, [data]);

  useEffect(() => {
    dispatch(setAppLoading(isLoading || loading));
  }, [isLoading, loading]);

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group row">
                <label htmlFor="status" className="col-sm-2 col-form-label">
                  สถานะ
                </label>
                <div className="col-sm-9">
                  <label className="toggle-switch toggle-switch-success">
                    <input
                      {...register("allowQuotaTransfer")}
                      type="checkbox"
                      defaultChecked={false}
                    />
                    <span className="toggle-slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-4 text-right">
                  <button className="btn btn-primary" type="submit">
                    บันทึก
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingQuotaTransferPage;
