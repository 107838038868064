import { FC, useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { IUser } from "../../interface/user";

interface IEidtAdminModal {
  user: IUser;
  show: boolean;
  toggle: Function;
  onSubmit: (id: string, data: string) => void;
}

const ChangePasswordAdminModal: FC<IEidtAdminModal> = ({
  user,
  show,
  toggle,
  onSubmit,
}) => {
  const password = useRef<HTMLInputElement>(null);
  const confirmPassword = useRef<HTMLInputElement>(null);
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);

  const handleShowPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      password.current?.setAttribute("type", "text");
      confirmPassword.current?.setAttribute("type", "text");
    } else {
      password.current?.setAttribute("type", "password");
      confirmPassword.current?.setAttribute("type", "password");
    }
  };

  useEffect(() => {
    setPasswordMatch(true);
    // eslint-disable-next-line
  }, [show]);

  return (
    <Modal size="lg" isOpen={show} toggle={() => toggle()} centered={false}>
      <div className="modal-title modal-header">
        <h5 className="modal-title">เพิ่มผู้ดูแลระบบ</h5>
        <button
          type="button"
          onClick={() => toggle()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <form
          autoComplete="false"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(user.id, password.current?.value ?? "");
          }}
        >
          <div className="form-group row">
            <div className="col-12 col-md-3">
              <label htmlFor="" className="required">
                อีเมล
              </label>
            </div>
            <div className="col-12 col-md-9 col-lg-8">
              <input
                className="form-control"
                type="email"
                disabled
                defaultValue={user.email}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-12 col-md-3">
              <label htmlFor="" className="required">
                รหัสผ่าน
              </label>
            </div>
            <div className="col-12 col-md-9 col-lg-8">
              {!passwordMatch && (
                <span className="text-danger mb-1">รหัสผ่านไม่ตรงกัน</span>
              )}
              <input
                ref={password}
                className="form-control"
                autoComplete="new-password"
                type="password"
                required
                onChange={(e) => {
                  setPasswordMatch(
                    e.target.value === confirmPassword.current?.value
                  );
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-12 col-md-3">
              <label htmlFor="name" className="required">
                ยืนยันรหัสผ่าน
              </label>
            </div>
            <div className="col-12 col-md-9 col-lg-8">
              <input
                ref={confirmPassword}
                className="form-control"
                autoComplete="false"
                type="password"
                required
                onChange={(e) => {
                  setPasswordMatch(password.current?.value === e.target.value);
                }}
              />
              <div className="form-check mt-4 ml-2">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={(e) => handleShowPassword(e)}
                  />
                  Show Password
                  <i className="input-helper"></i>
                </label>
              </div>
            </div>
          </div>
          <div>
            <div className="row mt-3">
              <button
                type="button"
                onClick={() => toggle()}
                className="btn btn-light ml-auto mr-2"
              >
                ยกเลิก
              </button>
              <button
                disabled={!passwordMatch}
                type="submit"
                className="btn btn-primary mr-2"
              >
                บันทึกข้อมูล
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default ChangePasswordAdminModal;
