import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import _ from "lodash";
import { filterDealerApi, filterUsersApi } from "../../api/user";
import Datatable, { DatatableElement } from "../../component/datatable";
import Meta from "../../interface/meta";
import { FilterDealerRequest, IDealerProfile } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import { createRoot } from "react-dom/client";
import Pagination from "../../component/pagination";
import Breadcrumb from "../../component/breadcrumb";
import ModalViewImage from "../../component/modal-view-image";
import { getSrcImage } from "../../utils/utils";
import { toBath } from "../../utils/moneyFormatter";
import moment from "moment";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";

const DealerList = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IDealerProfile[]>([]);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const name = useRef<HTMLInputElement>(null);
  const status = useRef<HTMLSelectElement>(null);
  const table = useRef<DatatableElement>(null);
  const [filter, setFilter] = useState<FilterDealerRequest>({
    dealerName: "",
    isOpen: [true, false],
    limit: 10,
    page: 1,
    name: "",
    status: [1, 0],
  });
  const debouncer = useMemo(() => {
    return _.debounce((fn: Function) => {
      fn();
    }, 300);
  }, []);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [debounceFilter, setDebounceFilter] = useState<FilterDealerRequest>({
    dealerName: "",
    isOpen: [true, false],
    limit: 10,
    page: 1,
    name: "",
    status: [1, 0],
  });

  const { data: userFilter } = useQuery({
    queryKey: ["fetchUserFilter"],
    queryFn: async () => {
      return (
        await filterDealerApi({
          dealerName: "",
          isOpen: [true, false],
          limit: 9999999,
          page: 1,
          name: "",
          status: [1, 0],
        })
      ).data;
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });
  const csvData = useMemo(() => {
    const lines = [
      [
        "รหัส",
        "รหัสตัวแทนจำหน่วย",
        "ชื่อ-นามสกุล",
        "สถานะ",
        "ร้านค้า",
        "วันที่สมัคร",
      ], // header
    ];
    userFilter?.results.forEach((user) => {
      lines.push([
        `${user.userNo}`,
        `${user.dealerNo}`,
        `${user.firstName} ${user.lastName}`,
        `${user.status === 0 ? "ระงับ" : "ใช้งาน"}`,
        `${user.dealerInfo.isOpen ? "เปิดร้าน" : "ปิดร้าน"}`,
        `${moment(user.createdAt).format("YYYY/MM/DD")}`,
      ]);
    });
    // const lineStr = lines.reduce((a, b) => {
    //   return (a += b.join(",") + "\n");
    // }, "");
    return lines;
  }, [userFilter]);

  const { data } = useQuery({
    queryKey: ["dealerList", debounceFilter],
    queryFn: async () => {
      return await filterDealerApi(filter);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const dealerPagination = useMemo(() => {
    return data?.data || null;
  }, [data]);

  useEffect(() => {
    if (dealerPagination) {
      setMeta({ ...dealerPagination.meta });
    } else {
      setMeta({
        hasMore: true,
        limit: filter.limit,
        page: 1,
        totalCount: 0,
      });
    }
  }, [dealerPagination, filter]);

  useEffect(() => {
    return () => {
      debouncer.cancel();
    };
  }, [debouncer]);

  useEffect(() => {
    debouncer(() => {
      setDebounceFilter({ ...filter });
    });
  }, [debouncer, filter]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <Breadcrumb
          title={"ตัวแทนจำหน่าย"}
          children={[{ title: "หน้าแรก", link: "/" }, { title: "สมาชิก" }]}
        />
        <button
          className="btn btn-primary"
          onClick={() => navigate(`${process.env.PUBLIC_URL}/dealers/add`)}
        >
          <i className="fa fa-plus mr-2" />
          เพิ่มตัวแทนจำหน่าย
        </button>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h3 className="col-lg-4 mt-2 mb-2">
                  ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
                </h3>
                <div className="col-lg p-0">
                  <div className="form-row mb-2">
                    <div className="col-lg-4 ml-auto mb-2">
                      <input
                        type="text"
                        className="form-control global_filter"
                        placeholder="ค้นหาชื่อสมาชิก"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    {/* <div className='col-lg-2 mb-2'>
                      <select className="form-control" ref={isDealer} onChange={() => {
                        filterUsers(1, meta.limit)
                      }}>
                        <option value="">ทั้งหมด</option>
                        <option value="true">ตัวแทน</option>
                        <option value="false">สมาชิก</option>
                      </select>
                    </div> */}

                    <div className="col-lg-2 mb-2">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            status:
                              e.target.value === "all"
                                ? [1, 0]
                                : [parseInt(e.target.value, 10) as 0 | 1],
                          }));
                        }}
                      >
                        <option value="all">ทั้งหมด</option>
                        <option value="1">ใช้งาน</option>
                        <option value="0">ระงับ</option>
                      </select>
                    </div>

                    <div className="col-auto  mb-2">
                      <CSVLink
                        data={csvData}
                        separator=","
                        filename={`รายชื่อตัวแทนจำหน่าย`}
                        target="_blank"
                      >
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                        >
                          <i className="fa fa-download mr-2" />
                          Export
                        </button>
                      </CSVLink>
                    </div>
                  </div>
                </div>
              </div>
              <Datatable
                ref={table}
                data={dealerPagination?.results ?? []}
                columns={[
                  {
                    title: "รูป",
                    width: "80px",
                    data: null,
                    createdCell(cell, cellData, rowData: IDealerProfile) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        });
                      };
                      createRoot(cell).render(
                        <div
                          className="cursor-pointer img-thumbmail"
                          onClick={() => {
                            cb(getSrcImage(rowData.picture));
                          }}
                        >
                          <img
                            className="lazy img-responsive"
                            alt=""
                            src={getSrcImage(rowData.picture)}
                          />
                        </div>
                      );
                    },
                  },
                  {
                    title: "รหัสสมาชิก",
                    width: 100,
                    data: "userNo",
                  },
                  {
                    title: "รหัสตัวแทนจำหน่าย",
                    width: 100,
                    data: "dealerNo",
                  },
                  {
                    title: "ชื่อ-นามสกุล",
                    width: 200,
                    render: (_1, _2, row: IDealerProfile) => {
                      return `${row.firstName} ${row.lastName}`;
                    },
                  },

                  // {
                  //   title: "รายได้",
                  //   width: 120,
                  //   data: null,
                  //   createdCell(cell, cellData, rowData: IDealerProfile) {
                  //     createRoot(cell).render(
                  //       <div
                  //         onClick={() => {
                  //           navigate(
                  //             `${process.env.PUBLIC_URL}/users/income/${rowData.id}`
                  //           );
                  //         }}
                  //         className="btn btn-outline-primary mr-1 mb-1 pt-2"
                  //       >
                  //         {toBath(rowData.credit)}
                  //       </div>
                  //     );
                  //   },
                  // },
                  // {
                  //   title: "การใช้งาน",
                  //   width: 120,
                  //   data: null,
                  //   createdCell(cell, cellData, rowData: IDealerProfile) {
                  //     createRoot(cell).render(
                  //       <div
                  //         onClick={() => {
                  //           navigate(
                  //             `${process.env.PUBLIC_URL}/users/history/${rowData.id}`
                  //           );
                  //         }}
                  //         className="btn btn-outline-primary mr-1 mb-1 pt-2"
                  //       >
                  //         <span className="fa fa-eye"></span>
                  //       </div>
                  //     );
                  //   },
                  // },
                  // {
                  //   title: "บัญชี",
                  //   width: 100,
                  //   data: null,
                  //   createdCell(cell, cellData, rowData: IDealerProfile) {
                  //     createRoot(cell).render(
                  //       <div
                  //         onClick={() => {
                  //           navigate(
                  //             `${process.env.PUBLIC_URL}/users/book-bank/${rowData.id}`
                  //           );
                  //         }}
                  //         className="btn btn-outline-primary mr-1 mb-1 pt-2"
                  //       >
                  //         <span className="fa fa-eye"></span>
                  //       </div>
                  //     );
                  //   },
                  // },
                  // {
                  //   title: "ทีมงาน",
                  //   width: 100,
                  //   data: null,
                  //   createdCell(cell, cellData, rowData: IDealerProfile) {
                  //     createRoot(cell).render(
                  //       <div
                  //         onClick={() => {
                  //           navigate(
                  //             `${process.env.PUBLIC_URL}/users/team/${rowData.id}`
                  //           );
                  //         }}
                  //         className="btn btn-outline-primary mr-1 mb-1 pt-2"
                  //       >
                  //         <span className="fa fa-eye"></span>
                  //       </div>
                  //     );
                  //   },
                  // },
                  // {
                  //   title: "ประเภท",
                  //   width: 100,
                  //   data: "isDealer",
                  //   render(data: boolean, type, row, meta) {
                  //     if (data) return "<span class='text-success'>ตัวแทน</span>";
                  //     return "<span class='text-info'>สมาชิก</span>";
                  //   },
                  // },
                  {
                    title: "สถานะ",
                    width: 100,
                    data: "status",
                    render: (data: number) => {
                      switch (data) {
                        case 0:
                          return "<span class='badge badge-danger'>ระงับ</span>";
                        case 1:
                          return "<span class='badge badge-success'>ใช้งาน</span>";
                        default:
                          return "";
                      }
                    },
                  },
                  {
                    title: "ร้านค้า",
                    width: 100,
                    data: "dealerInfo.isOpen",
                    render: (data: boolean) => {
                      if (data) {
                        return "<span class='badge badge-success'>เปิดขาย</span>";
                      }
                      return "<span class='badge badge-danger'>ปิดร้าน</span>";
                    },
                  },
                  {
                    title: "วันที่สมัคร",
                    width: 100,
                    data: "createdAt",
                    render: (data: string) => {
                      return moment(data).format("YYYY/MM/DD");
                    },
                  },
                ]}
                actions={[
                  {
                    button: {
                      callback(data: IDealerProfile) {
                        navigate(
                          `${process.env.PUBLIC_URL}/dealers/quota/${data.id}`
                        );
                      },
                      code: (
                        <button className="btn btn-outline-primary btn-sm mb-1">
                          โควต้า
                        </button>
                      ),
                    },
                  },
                  {
                    button: {
                      callback(data: IDealerProfile) {
                        navigate(
                          `${process.env.PUBLIC_URL}/dealers/profile/${data.id}`
                        );
                      },
                      code: (
                        <button className="btn btn-outline-primary btn-sm mb-1">
                          แก้ไข
                        </button>
                      ),
                    },
                  },
                  // {
                  //   button: {
                  //     callback(data: IDealerProfile) {
                  //       navigate(
                  //         `${process.env.PUBLIC_URL}/dealer/edit/${data.id}`
                  //       );
                  //     },
                  //     code: (
                  //       <button className="btn btn-outline-primary btn-sm mb-1">
                  //         แก้ไข
                  //       </button>
                  //     ),
                  //   },
                  // },
                  // {
                  //   button: {
                  //     callback(data: IDealerProfile) {
                  //       navigate(
                  //         `${process.env.PUBLIC_URL}/dealer/edit/${data.id}`
                  //       );
                  //     },
                  //     code: (
                  //       <button className="btn btn-outline-primary btn-sm mb-1">
                  //         แก้ไข
                  //       </button>
                  //     ),
                  //   },
                  // },
                ]}
              />

              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  setFilter((prev) => ({ ...prev, page, limit: meta.limit }));
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};

export default DealerList;
