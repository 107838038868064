import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { getPeriodLotteryByIdApi } from "../../api/lottery";
import useSnackbar from "../../utils/snackbar";

interface IFormPeriodLotteryModal {
  mode: "new" | "edit";
  open: boolean;
  id?: string | null;
  toggle: () => void;
  onSubmit: (payload: {
    period: string;
    openDate: string;
    openTime: string;
    closeDate: string;
    closeTime: string;
    status: number;
  }) => void;
}
const FormPeriodLotteryModal: FC<IFormPeriodLotteryModal> = ({
  mode,
  open,
  id,
  toggle,
  onSubmit,
}) => {
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });

  const [payload, setPayload] = useState({
    period: moment().format("yyyy-MM-DD"),
    openDate: moment().format("yyyy-MM-DD"),
    openTime: "00:00:00",
    closeDate: moment().format("yyyy-MM-DD"),
    closeTime: "00:00:00",
    status: 0,
  });

  const getPeriodLotteryById = async () => {
    try {
      const { data } = await getPeriodLotteryByIdApi(id!);
      setPayload({
        period: moment(data.periodDate).format("yyyy-MM-DD"),
        openDate: moment(data.openDatetime).format("yyyy-MM-DD"),
        openTime: moment(data.openDatetime).format("HH:mm:ss"),
        closeDate: moment(data.closeDatetime).format("yyyy-MM-DD"),
        closeTime: moment(data.closeDatetime).format("HH:mm:ss"),
        status: data.status,
      });
    } catch (e: any) {
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    if (!open) {
      setPayload({
        period: moment().format("yyyy-MM-DD"),
        openDate: moment().format("yyyy-MM-DD"),
        openTime: "00:00:00",
        closeDate: moment().format("yyyy-MM-DD"),
        closeTime: "00:00:00",
        status: 0,
      });
    } else {
      if (mode === "edit") {
        getPeriodLotteryById();
      }
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Modal size="md" isOpen={open} toggle={toggle} centered={false}>
      <div className="modal-title modal-header">
        <h5 className="modal-title">งวดลอตเตอรี่</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(payload);
          }}
        >
          <div className="container">
            <div className="form-group row">
              <div className="col-lg-12">
                <label htmlFor="">งวดประจำวันที่</label>
                <input
                  type="date"
                  className="form-control"
                  value={payload.period}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      period: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-12">เริ่มขาย</div>
              <div className="col-lg-6">
                <input
                  type="date"
                  className="form-control"
                  value={payload.openDate}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      openDate: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-lg-6">
                <input
                  type="time"
                  className="form-control"
                  value={payload.openTime}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      openTime: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-12">สิ้นสุด</div>
              <div className="col-lg-6">
                <input
                  type="date"
                  className="form-control"
                  value={payload.closeDate}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      closeDate: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-lg-6">
                <input
                  type="time"
                  className="form-control"
                  value={payload.closeTime}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      closeTime: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-12">สถานะ</div>
              <div className="col-12">
                <select
                  className="form-control"
                  value={payload.status}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      status: +e.target.value,
                    }));
                  }}
                >
                  <option value={1}>เปิด</option>
                  <option value={0}>ปิด</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group text-right">
            <button type="submit" className="btn btn-success mr-2">
              บันทึก
            </button>
            <button type="button" className="btn btn-light" onClick={toggle}>
              ยกเลิก
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default FormPeriodLotteryModal;
